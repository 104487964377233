import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import AddProfileModal from '@forms/AddProfileToOrganization';
import EditLinkedProfileModal from '@forms/EditProfileLinkedToOrg';
import {
  getOrganizationBundle,
  getOrganizationInvites,
  removeProfilesFromOrganization,
  resendInvite,
  revokeInvite,
} from '@providers/organization';
import { getOrganizationSubscriptionDetails } from '@providers/subscription';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StatusBadge from './badges/StatusBadge';

// TODO implement later for setting Profile status in regard of the license of the sub.
// function StatusSelect(props) {
//   const { status } = props;
//   const [activeStatus, setActiveStatus] = useState(status);
//   const statusOptions = ['Activated', 'Revoked'];
//   return (
//     <RadioGroup
//       value={activeStatus}
//       onChange={setActiveStatus}
//       className="flex cursor-pointer gap-2"
//     >
//       {statusOptions.map((statusOption) => (
//         <RadioGroup.Option
//           key={statusOption}
//           value={statusOption}
//           as="div"
//           className="flex items-center"
//         >
//           {({ checked }) => <StatusBadge badgeStatus={statusOption} checked={checked} />}
//         </RadioGroup.Option>
//       ))}
//     </RadioGroup>
//   );
// }

function getHeaderPadding(index) {
  switch (index) {
    case 0:
      return '20px';
    case 1:
      return '35px';
    case 2:
      return '160px';
    case 3:
      return '180px';
    default:
      return '5px';
  }
}

function GridHeader({ headerData }) {
  return (
    <thead>
      <tr className="flex rounded-t-lg bg-primary-light py-4 font-bold md:px-5 lg:px-6">
        {headerData.map((value, index) => (
          <td
            key={index}
            style={{ paddingLeft: getHeaderPadding(index) }}
            className="w-1/5 text-center"
          >
            {value}
          </td>
        ))}
      </tr>
    </thead>
  );
}

function GridRow({
  rowData,
  organizationId,
  setForceUpdate,
  selectedProfiles,
  toggleRowSelection,
  organizationBundleExpirationDate,
}) {
  const { name, email, status, licenceStartDate, licenceEndDate, userType, profileId, bundleId } =
    rowData;

  const [showModal, setShowModal] = useState(false);
  const [licenceStatus, setLicenceStatus] = useState(rowData.licenceStatus);

  const isSelected = selectedProfiles.some((profile) => profile.email === email);

  const handleToggleRowSelection = () => {
    if (profileId) {
      toggleRowSelection(email, profileId);
    }
  };

  const onClickModal = () => {
    setShowModal(!showModal);
  };

  function licenceStatusTranslate(state) {
    if (status === 'PENDING') {
      state = 'Behandeling';
    }
    const states = {
      ACTIVE: 'Actief',
      NOT_ACTIVE: 'Ingetrokken',
    };
    return states[state] || state;
  }

  // useEffect(() => {
  //   async function getInvitations() {
  //     const response = await getOrganizationInvites(organizationId);

  //     if (response && response.status !== 200) {
  //       return;
  //     }

  //     const invitationData = response.data;

  //     const updatedGridData = {
  //       header: ['Name', 'Email', 'Uitnodiging', 'Licentie'],
  //       rows: invitationData.map((item) => ({
  //         name: `${(item.firstname || '').trim()} ${(item.lastname_prefix || '').trim()} ${(
  //           item.lastname || ''
  //         ).trim()}`,
  //         email: item.email,
  //         licenceStatus: item.license_status,
  //         status: item.invite_status,
  //         licenceStartDate: item.start_date,
  //         licenceEndDate: item.expiration_date,
  //         userType: item.role,
  //         bundleId: item.bundle_id,
  //         profileId: item.profile_id,
  //       })),
  //     };
  //     setGridData(updatedGridData);
  //   }

  //   getInvitations();
  // }, [showModal]);

  return (
    <tr
      className={`flex gap-1 border-b py-3 font-primary md:px-5 lg:px-8 ${isSelected ? 'bg-gray-50' : ''}`}
    >
      <td className="w-1/12 text-left">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={handleToggleRowSelection}
          className="size-4 rounded-md border-gray-300"
        />
      </td>
      <td className="w-3/12 text-left font-bold">{name}</td>
      <td className="w-4/12 text-left text-primary-dark/70">{email}</td>
      <td className="flex w-3/12 items-center">
        {renderStatusCellContent(status, email, organizationId, setForceUpdate)}
      </td>
      <td className="w-2/12 pl-4 text-left text-primary-dark/70">
        {licenceStatusTranslate(licenceStatus)}
      </td>
      <td className="text-right">
        {profileId ? (
          <Button
            icon="pencil"
            type="button"
            buttonType=""
            overrideClass="pl-2 pt-1 pb-1 text-accent/70 hover:text-accent shadow-none"
            onClick={() => onClickModal()}
          />
        ) : (
          <Button
            icon="pencil"
            type="button"
            buttonType=""
            overrideClass="pl-2 pt-1 pb-1 text-accent/70 hover:text-accent shadow-none"
          />
        )}
      </td>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Wijzig toegang tot de licentie voor:"
        modalContent={
          <div>
            <EditLinkedProfileModal
              email={email}
              licenceStatus={licenceStatus}
              licenceStartDate={licenceStartDate}
              licenceEndDate={licenceEndDate}
              setShowModal={setShowModal}
              userType={userType}
              bundleId={bundleId}
              profileId={profileId}
              organizationId={organizationId}
              organizationBundleExpirationDate={organizationBundleExpirationDate}
              setLicenceStatus={setLicenceStatus}
            />
          </div>
        }
      />
    </tr>
  );
}

function renderStatusCellContent(status, email, organizationId, setForceUpdate) {
  async function onClickRevokeInvite() {
    const payload = {
      organization_id: organizationId,
      email: email,
    };

    const response = await revokeInvite(payload);

    if (response && response.status === 200) {
      toast.success('Uitnodiging ingetrokken.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });

      setForceUpdate((prev) => !prev);
    } else {
      toast.error('Kan de uitnodiging niet intrekken.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }

  async function onClickResendInvite() {
    const revokeData = {
      organization_id: organizationId,
      email: email,
    };

    const response = await resendInvite(revokeData);

    if (response && response.status === 200) {
      toast.success('De uitnodiging is opnieuw verzonden.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });

      setForceUpdate((prev) => !prev);
    } else {
      toast.error('Kan de uitnodiging niet opnieuw verzenden.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }

  if (status === 'EXPIRED' || status === 'FAILED' || status === 'REVOKED') {
    return (
      <>
        <div className="flex gap-2" style={{ zIndex: 0 }}>
          <StatusBadge badgeStatus={status} />
          <Button
            type="button"
            icon="rotate"
            iconSize="xs"
            buttonType="secondary"
            className
            onClick={async () => await onClickResendInvite()}
            overrideClass="h-6 w-5"
          />
        </div>
      </>
    );
  } else if (status === 'PENDING') {
    return (
      <>
        <div className="flex gap-2" style={{ zIndex: 0 }}>
          <StatusBadge badgeStatus={status} />
          <Button
            type="button"
            icon="x"
            iconSize="xs"
            className
            onClick={async () => await onClickRevokeInvite()}
            overrideClass="h-6 w-5 bg-functional-error-light hover:bg-functional-error/30"
          />
        </div>
      </>
    );
  }
  return <StatusBadge badgeStatus={status} />;
}

export default function Grid(props) {
  const { setSelectedIndex } = props;
  const [gridData, setGridData] = useState({
    header: ['Name', 'Email', 'Uitnodiging', 'Licentie'],
    rows: [],
  });

  const [showModalAdd, setShowModalAdd] = useState(false);
  const { organizationId } = userStore();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [inviteButtonState, setInviteButtonState] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const toggleRowSelection = (email, profileId) => {
    const isSelected = selectedProfiles.some((profile) => profile.email === email);
    if (isSelected) {
      setSelectedProfiles(selectedProfiles.filter((profile) => profile.email !== email));
    } else {
      setSelectedProfiles([...selectedProfiles, { email, profileId }]);
    }
  };

  async function onClickDeleteProfile() {
    // Build the payload to update the db in profile & license
    const payload = {
      profile_ids: selectedProfiles.map((profile) => profile.profileId),
      emails: selectedProfiles.map((profile) => profile.email),
    };

    const response = await removeProfilesFromOrganization(organizationId, payload);

    if (response && response.status === 204) {
      toast.success('Gebruikers zijn ontkoppeld.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });

      setSelectedProfiles([]);
      setShowConfirmation(false);
      setForceUpdate((prev) => !prev);
    } else {
      toast.error('Kan gebruikers niet ontkoppelen.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }
  const [organizationBundleExpirationDate, setOrganizationBundleExpirationDate] = useState();

  const onClickModalAdd = () => {
    setSelectedProfiles([]);
    setShowModalAdd(!showModalAdd);
  };

  async function fetchOrganizationBundleExpirationDate() {
    const response = await getOrganizationBundle(organizationId);

    if (response && response.status === 200) {
      setOrganizationBundleExpirationDate(response.data.expiration_date);
    }
  }

  useEffect(() => {
    async function getInvitations() {
      const response = await getOrganizationInvites(organizationId);
      if (response && response.status !== 200) {
        return;
      }
      if (response && response.status === 200) {
        const invitationData = response.data;

        const updatedGridData = {
          header: ['Name', 'Email', 'Uitnodiging', 'Licentie'],
          rows: invitationData.map((item) => ({
            name: `${(item.firstname || '').trim()} ${(item.lastname_prefix || '').trim()} ${(
              item.lastname || ''
            ).trim()}`,
            email: item.email,
            licenceStatus: item.license_status,
            status: item.invite_status,
            licenceStartDate: item.start_date,
            licenceEndDate: item.expiration_date,
            userType: item.role,
            bundleId: item.bundle_id,
            profileId: item.profile_id,
          })),
        };
        setGridData(updatedGridData);
      }

      const subscriptionResponse = await getOrganizationSubscriptionDetails(organizationId);

      if (subscriptionResponse && subscriptionResponse.status === 200) {
        const { active, pending, total } = subscriptionResponse.data.subscription_member_count;
        let seats = active + pending;
        if (seats === total) {
          setInviteButtonState(true);
        } else {
          setInviteButtonState(false);
        }
      }
    }

    getInvitations();
  }, [showModalAdd, forceUpdate, organizationId]);

  useEffect(() => {
    fetchOrganizationBundleExpirationDate();
  }, []);

  return (
    <div className="container mx-auto overflow-x-auto sm:mx-6 lg:-mx-1">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h2 className="font-primary text-3xl">Profielen</h2>
          <p className="mb-8 mt-1 font-secondary">
            Bekijk de status van alle gekoppelde profielen.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <Button
            type="button"
            label="Nodig profiel uit"
            buttonType="primary"
            onClick={() => {
              if (!inviteButtonState) {
                onClickModalAdd();
              }
            }}
            disabled={inviteButtonState}
            hoverText={
              inviteButtonState ? 'Er zijn geen plaatsen beschikbaar. Upgrade de licentie' : ''
            }
          />
          {inviteButtonState && (
            <Button
              type="button"
              label="Breid licentie uit"
              buttonType="primary"
              hoverText="Upgrade de licentie"
              onClick={() => {
                setSelectedIndex(3);
              }}
            />
          )}
          {selectedProfiles && selectedProfiles.length > 0 && (
            <Button
              type="button"
              label="Profiel verwijderen"
              buttonType="danger"
              hoverText="Verwijder profiel uit organisatie"
              onClick={() => setShowConfirmation(true)}
            />
          )}
        </div>
      </div>

      <div className="mb-12 flex min-w-full flex-col rounded-lg bg-white shadow-lg">
        <table className="min-w-full">
          <GridHeader headerData={gridData.header} />
          <tbody className="rounded-lg bg-white">
            {gridData.rows.map((row, index) => (
              <GridRow
                key={index}
                rowData={row}
                organizationId={organizationId}
                forceUpdate={forceUpdate}
                setForceUpdate={setForceUpdate}
                setGridData={setGridData}
                organizationBundleExpirationDate={organizationBundleExpirationDate}
                selectedProfiles={selectedProfiles}
                toggleRowSelection={toggleRowSelection}
              />
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        showModal={showModalAdd}
        setShowModal={setShowModalAdd}
        modalTitle="Voeg profiel toe"
        modalContent={
          <AddProfileModal
            setShowModalAdd={setShowModalAdd}
            organizationBundleExpirationDate={organizationBundleExpirationDate}
          />
        }
      />

      {showConfirmation && (
        <div className="fixed left-0 top-0 flex size-full items-center justify-center bg-gray-800/75">
          <div className="rounded-lg bg-white p-8">
            <h2 className="pb-2 font-primary font-bold">Profiel ontkoppelen van organisatie</h2>
            <p className="mb-4">
              Weet je zeker dat je de selectie wilt verwijderen?
              <br />
              Hierdoor hebben ze geen toegang meer tot de licentie.
              <br /> Het persoonlijke BouwZo profiel blijft wel bestaan.
            </p>
            <div className="flex justify-end gap-3 pt-2">
              <Button
                type="button"
                buttonType="danger"
                onClick={async () => await onClickDeleteProfile()}
                label="Verwijder Profiel(en)"
              />
              <Button
                type="button"
                buttonType="secondary"
                onClick={() => setShowConfirmation(false)}
                label="Annuleren"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
