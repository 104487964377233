import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import DatePicker from '@elements/forms/DatePicker';
import { RadioGroup, Switch } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import { updateLinkedProfile } from '@providers/organization';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const timeOptions = [{ name: '3' }, { name: '6' }, { name: '12' }, { name: '18' }];

export default function EditLinkedProfileModal({
  email,
  licenceStatus,
  licenceStartDate,
  licenceEndDate,
  setShowModal,
  profileId,
  bundleId /*userType*/,
  organizationId,
  organizationBundleExpirationDate,
  setLicenceStatus = () => {},
}) {
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [isDatePickerDisabled, setDatePickerDisabled] = useState(false);
  const [selected, setSelected] = useState('');
  const availableMonths = timeOptions.map((option) => option.name);
  const isDisabledMonthSelected = availableMonths.includes(selected);
  const [expirationDateIsPassedBundleDate, setExpirationDateIsPassedBundleDate] = useState(false);
  const [status, setStatus] = useState(licenceStatus);

  const handleDateChange = (pickerID, selectedDate) => {
    if (pickerID === 'picker1') {
      setStartDate(selectedDate);
    } else if (pickerID === 'picker2') {
      if (organizationBundleExpirationDate) {
        const bundleExpirationDate = new Date(organizationBundleExpirationDate);
        const expirationDate = new Date(selectedDate);
        if (expirationDate > bundleExpirationDate) {
          setExpirationDateIsPassedBundleDate(true);
        } else {
          setExpirationDateIsPassedBundleDate(false);
        }
      }
      setEndDate(selectedDate);
    }
  };

  function onCancelClick() {
    setShowModal(false);
  }

  async function editProfileData() {
    const updateProfileData = {
      organization_id: organizationId,
      bundle_id: bundleId,
      email: email,
      is_active: status === 'ACTIVE' ? true : false,
      start_date: startDate,
      expiration_date: endDate,
    };

    const response = await updateLinkedProfile(profileId, updateProfileData);

    if (response && response.status === 200) {
      setShowModal(false);
      toast.success('Profiel succesvol bewerkt.', {
        position: 'top-right',
        icon: <Icon iconName="check" color={'text-green-500'} />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setLicenceStatus(status);
    } else {
      toast.error('Kan profiel niet bewerken, verwijderd uit organisatie', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
    }
  }

  const toggleDatePicker = (pickerID) => {
    if (pickerID === 'picker1') {
      setOpenStartDate(!openStartDate);
      setOpenEndDate(false); // Close the other date picker if it's open
    } else if (pickerID === 'picker2') {
      setOpenEndDate(!openEndDate);
      setOpenStartDate(false); // Close the other date picker if it's open
    }
  };

  const calculateMonthDifference = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    return months <= 0 ? 0 : months;
  };

  const handleTimeChange = (selectedOption) => {
    const months = parseInt(selectedOption.name);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + months);
    setEndDate(endDate);
    handleDateChange('picker2', endDate);

    setDatePickerDisabled(true);
  };

  useEffect(() => {
    const monthDifference = calculateMonthDifference(
      new Date(licenceStartDate),
      new Date(licenceEndDate),
    );
    const matchedOption = timeOptions.find((option) => parseInt(option.name) === monthDifference);
    if (matchedOption) {
      setSelected(matchedOption.name);
    }
  }, [licenceStartDate, licenceEndDate]);

  useEffect(() => {
    setDatePickerDisabled(isDisabledMonthSelected);
  }, [selected]);

  function ActiveSwitch({ title, rightValue, leftValue, status, onStatusChange }) {
    const [enabled, setEnabled] = useState(status);

    const handleChange = () => {
      const newStatus = enabled ? 'NOT_ACTIVE' : 'ACTIVE';
      setEnabled(!enabled);
      onStatusChange(newStatus);
    };

    useEffect(() => {
      setEnabled(status === 'ACTIVE');
    }, [status]);

    return (
      <div>
        <Switch.Group>
          <div className="flex flex-col justify-between gap-4 outline-none md:flex-row md:gap-0">
            <h2 className="pb-1 pr-20 font-secondary text-sm font-bold">{title}*</h2>
            <div className="flex flex-row items-center gap-8">
              <div className={classNames('font-secondary text-sm', !enabled && 'font-bold')}>
                {leftValue}
              </div>
              <Switch
                checked={enabled}
                onChange={handleChange}
                className={classNames`${
                  enabled ? 'bg-accent' : 'bg-accent-4'
                } relative inline-flex h-6 w-11 items-center rounded-full outline-none transition-colors`}
              >
                <span
                  className={`${
                    enabled ? 'translate-x-6' : 'translate-x-1'
                  } inline-block size-4 rounded-full bg-white outline-none transition-transform`}
                />
              </Switch>
              <div className={classNames('font-secondary text-sm', enabled && 'font-bold')}>
                {rightValue}
              </div>
            </div>
          </div>
        </Switch.Group>
      </div>
    );
  }

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1); //Had to add like this to enable the user to select today

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-8">
        <h1 className="pl-1 font-primary text-primary-dark/70">{email}</h1>
        <div className="flex w-96 flex-col gap-8">
          <ActiveSwitch
            title="Licentie&nbsp;toegang"
            rightValue="Actief"
            leftValue="Inactief"
            status={status}
            onStatusChange={(newStatus) => setStatus(newStatus)}
          />
          {/* <ActiveSwitch title="Beheerder" rightValue="Ja" leftValue="Nee" toggleType={userType} /> */}
        </div>
        <div>
          <div className="flex items-center justify-between">
            <h2 className="pb-2 pr-10 font-secondary text-sm font-bold">Looptijd in maanden</h2>
          </div>
          <RadioGroup
            value={selected}
            onChange={(value) => {
              setSelected(value); // Update the state with the new selection
              const selectedOption = timeOptions.find((option) => option.name === value);
              handleTimeChange(selectedOption); // Handle the change
            }}
          >
            <div className="flex flex-row justify-between gap-4 sm:justify-normal md:gap-8">
              {timeOptions.map((option) => (
                <RadioGroup.Option
                  key={option.name}
                  value={option.name}
                  className={({ checked }) =>
                    classNames(
                      'flex items-center justify-center rounded-md py-2 text-sm font-secondary uppercase w-full md:w-16',
                      'ring-1 ring-inset',
                      checked
                        ? 'ring-2 ring-accent bg-gray-50 cursor-pointer' // Styles for checked option
                        : 'ring-1 bg-gray-100 ring-gray-400/30 cursor-pointer', // Styles for unchecked option
                    )
                  }
                  disabled={status === 'NOT_ACTIVE'}
                >
                  {({ checked }) => (
                    <RadioGroup.Label
                      as="span"
                      className={`${
                        checked ? 'font-bold text-primary-dark/80' : 'text-primary-dark/80'
                      } block text-sm`}
                    >
                      {option.name}
                    </RadioGroup.Label>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        <DatePicker
          defaultValue={new Date(licenceStartDate)}
          isOpen={openStartDate}
          toggleOpen={() => toggleDatePicker('picker1')}
          onDateChange={(date) => handleDateChange('picker1', date)}
          label="Begin datum*"
          minDate={currentDate} // rule 1: The Begin datum can not be in the past as in before today
          maxDate={endDate ? new Date(endDate) : null} // rule 3: The Begin datum can never be after the einddatum
          setOpenStartDate={setOpenStartDate}
          disableClearButton={true}
          disabled={status === 'NOT_ACTIVE' || isDatePickerDisabled}
        />
        <div className="flex flex-col gap-4">
          <DatePicker
            defaultValue={licenceEndDate ? new Date(licenceEndDate) : null}
            value={endDate}
            isOpen={openEndDate}
            toggleOpen={() => toggleDatePicker('picker2')}
            onDateChange={(date) => handleDateChange('picker2', date)}
            label="Einddatum"
            minDate={startDate} // rule 2: The Einddatum can never be before the Begin datum
            setOpenEndDate={setOpenEndDate}
            disabled={status === 'NOT_ACTIVE' || isDatePickerDisabled}
            expirationDateIsPassedBundleDate={expirationDateIsPassedBundleDate}
            organizationBundleExpirationDate={organizationBundleExpirationDate}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button label="Annuleren" type="button" buttonType="secondary" onClick={onCancelClick} />
          <Button
            label="Opslaan"
            type="button"
            buttonType="primary"
            onClick={editProfileData}
            disabled={expirationDateIsPassedBundleDate}
          />
        </div>
      </div>
    </div>
  );
}
