import { userStore } from '@context/userStore';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import { isOrganisationAdmin } from '@providers/enterprise';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IsOrganizationAdminModal from './IsOrganizationAdminModal';
import ProfileRemovalModalForm from './ProfileRemovalModalForm';

export default function ProfileRemoval() {
  const [showModal, setShowModal] = useState(false);
  const [showIsOrganizationAdminModal, setShowIsOrganizationAdminModal] = useState(false);
  const { profileId } = userStore();

  const modalFormProps = {
    setShowModal,
  };

  async function handleClick() {
    // Check if the user is an organisation admin
    // If they are, prevent them from removing the profile until the admin is changed
    const response = await isOrganisationAdmin(profileId);
    if (response && response.status === 200) {
      if (response.data.is_admin) {
        setShowIsOrganizationAdminModal(true);
      } else {
        setShowModal(true);
      }
    }
  }

  return (
    <div className="container mx-auto pb-10 font-primary">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <h2 className="font-primary text-3xl">Profiel verwijderen</h2>
          <p className="mb-8">Hier kun je je profiel verwijderen.</p>
        </div>
      </div>
      <ul className="flex w-full flex-col gap-4 rounded-lg bg-white px-8 py-4 shadow-lg">
        <p className="pt-2">Weet je zeker dat je je profiel definitief wilt verwijderen?</p>
        <p>
          Let op: Na het verwijderen van je profiel,{' '}
          <span className="font-bold underline">
            loopt je licentie door tot de einddatum van de huidige termijn.
          </span>{' '}
          Door het verwijderen van je contract heb je geen recht op terugbetaling van je resterende
          contracttermijn.
        </p>

        <p>
          Het verwijderen van je profiel is onomkeerbaar. Je verliest toegang tot al je licenties,
          aankopen, bestelgeschiedenis en facturen.
        </p>
        <div className="ml-auto py-2">
          <Button
            type="button"
            buttonType="danger"
            onClick={handleClick}
            label="Verwijder profiel"
            overrideClass="text-white/90"
          />
        </div>
      </ul>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Verwijder mijn profiel"
        modalContent={<ProfileRemovalModalForm {...modalFormProps} />}
      />
      <Modal
        showModal={showIsOrganizationAdminModal}
        setShowModal={setShowIsOrganizationAdminModal}
        modalTitle="Verwijder mijn profiel"
        modalContent={
          <IsOrganizationAdminModal
            setShowIsOrganizationAdminModal={setShowIsOrganizationAdminModal}
          />
        }
      />
      <ToastContainer theme="light" />
    </div>
  );
}
