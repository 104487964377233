import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Button from '@elements/buttons/Button';
import DatePicker from '@elements/forms/DatePicker';
import InputField from '@elements/forms/InputField';
import { formValidators } from '@forms/validators';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '@helpers/classNames';
import { handleValidation } from '@helpers/handleValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { getOrganizationInvites, inviteToOrganization } from '@providers/organization';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

const timeOptions = [{ name: '3' }, { name: '6' }, { name: '12' }, { name: '18' }];

export default function AddProfileModal({ setShowModalAdd, organizationBundleExpirationDate }) {
  const { organizationId } = userStore();
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [licenseStartDate, setStartDate] = useState(new Date());
  const [licenseEndDate, setEndDate] = useState(null);
  const [isValid, setIsValid] = useState({});
  const [isAdmin /*setIsAdmin*/] = useState(false);
  const [invitationEmails, setInvitationEmails] = useState([]);
  const [isDatePickerDisabled, setDatePickerDisabled] = useState(false);
  const [selected, setSelected] = useState('');
  const [expirationDateIsPassedBundleDate, setExpirationDateIsPassedBundleDate] = useState(false);

  useEffect(() => {
    async function getInvitations() {
      const response = await getOrganizationInvites(organizationId);
      if (response && response.status === 200) {
        const emails = response.data.map((item) => item.email);
        setInvitationEmails(emails);
      }
    }
    getInvitations();
  }, [organizationId]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .test('is-invited', 'Gebruiker al uitgenodigd', function (value) {
        return !invitationEmails.includes(value);
      })
      .required('Email is verplicht'),
    licenseStartDate: formValidators.startDateRequired,
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue('licenseStartDate', licenseStartDate, { shouldValidate: true });
  }, [setValue, licenseStartDate]);

  const handleDateChange = (pickerID, selectedDate) => {
    if (pickerID === 'picker1') {
      setStartDate(selectedDate);
    } else if (pickerID === 'picker2') {
      if (organizationBundleExpirationDate) {
        const bundleExpirationDate = new Date(organizationBundleExpirationDate);
        const expirationDate = new Date(selectedDate);
        if (expirationDate > bundleExpirationDate) {
          setExpirationDateIsPassedBundleDate(true);
        } else {
          setExpirationDateIsPassedBundleDate(false);
        }
      }
      setEndDate(selectedDate);
    }
  };

  const toggleDatePicker = (pickerID) => {
    if (pickerID === 'picker1') {
      setOpenStartDate(!openStartDate);
      setOpenEndDate(false); // Close the other date picker if it's open
    } else if (pickerID === 'picker2') {
      setOpenEndDate(!openEndDate);
      setOpenStartDate(false); // Close the other date picker if it's open
    }
  };

  function onCancelClick() {
    setShowModalAdd(false);
  }

  function handleValidationWrapper(e, error) {
    handleValidation(e, error, trigger, setIsValid);
  }

  // function onSwitchChange(newState) {
  //   setIsAdmin(newState);
  // }

  async function onSubmit(formData) {
    const sendInvite = {
      organization_id: organizationId,
      email: formData.email.toLowerCase(),
      license_start_date: format(licenseStartDate, 'yyyy-MM-dd'),
      invite_type: isAdmin ? 'ENTERPRISE_ADMIN' : 'ENTERPRISE_USER',
    };

    if (licenseEndDate) {
      sendInvite.license_end_date = format(licenseEndDate, 'yyyy-MM-dd');
    }

    const response = await inviteToOrganization(sendInvite);
    if (response && response.status === 201) {
      setShowModalAdd(false);
      toast.success('Uitnodiging succesvol verzonden.', {
        position: 'top-right',
        icon: <Icon iconName="check" color={'text-green-500'} />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
    }
  }

  const handleTimeChange = (selectedOption) => {
    const months = parseInt(selectedOption.name);
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + months);
    setEndDate(endDate);
    handleDateChange('picker2', endDate);

    setDatePickerDisabled(true);
  };

  // function ActiveSwitch({ enabled, onSwitchChange }) {
  //   return (
  //     <div className="flex flex-col">
  //       <Switch.Group>
  //         <div className="flex pt-2 outline-none">
  //           <h2 className="pr-10 font-secondary text-sm font-bold">Beheerder*</h2>
  //           <div className={classNames('mr-4 font-secondary text-sm', !enabled && 'font-bold')}>
  //             Nee
  //           </div>
  //           <Switch
  //             checked={enabled}
  //             onChange={onSwitchChange}
  //             className={classNames`${
  //               enabled ? 'bg-accent' : 'bg-accent-4'
  //             } relative inline-flex h-6 w-11 items-center rounded-full outline-none transition-colors`}
  //           >
  //             <span
  //               className={`${
  //                 enabled ? 'translate-x-6' : 'translate-x-1'
  //               } inline-block size-4 rounded-full bg-white outline-none transition-transform`}
  //             />
  //           </Switch>
  //           <div className={classNames('ml-4 font-secondary text-sm', enabled && 'font-bold')}>
  //             Ja
  //           </div>
  //         </div>
  //       </Switch.Group>
  //     </div>
  //   );
  // }

  return (
    <form onSubmit={handleSubmit((formData) => onSubmit(formData, isAdmin))}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <InputField
            label="Email*"
            name="email"
            type="text"
            placeholder="jan@bouwbedrijf.nl"
            register={register}
            onKeyUp={(e) => handleValidationWrapper(e, errors.email)}
            isValid={isValid['email']}
            message={errors.email?.message}
            errors={errors}
          />
          {/* <ActiveSwitch enabled={isAdmin} onSwitchChange={onSwitchChange} /> */}
          <div>
            <div className="flex items-center justify-between">
              <h2 className="pb-2 pr-10 font-secondary text-sm font-bold">Looptijd in maanden</h2>
            </div>
            <RadioGroup
              value={selected}
              onChange={(value) => {
                setSelected(value); // Update the state with the new selection
                const selectedOption = timeOptions.find((option) => option.name === value);
                handleTimeChange(selectedOption); // Handle the change
              }}
            >
              <div className="flex flex-row justify-between gap-8 sm:justify-normal md:gap-8">
                {timeOptions.map((option) => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option.name}
                    className={({ checked }) =>
                      classNames(
                        'flex items-center justify-center rounded-md py-2 text-sm font-secondary uppercase w-full md:w-16',
                        'ring-1 ring-inset',
                        checked
                          ? 'ring-2 ring-accent bg-gray-50 cursor-pointer' // Styles for checked option
                          : 'ring-1 bg-gray-100 ring-gray-400/30 cursor-pointer', // Styles for unchecked option
                      )
                    }
                  >
                    {({ checked }) => (
                      <RadioGroup.Label
                        as="span"
                        className={`text-primary-dark/80 ${
                          checked ? 'font-bold' : ''
                        } block cursor-pointer text-sm`}
                      >
                        {option.name}
                      </RadioGroup.Label>
                    )}
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
          <DatePicker
            defaultValue={new Date()}
            isOpen={openStartDate}
            name="licenseStartDate"
            disabled={isDatePickerDisabled}
            register={register}
            toggleOpen={() => toggleDatePicker('picker1')}
            onDateChange={(date) => {
              handleDateChange('picker1', date);
              handleValidationWrapper(
                { target: { name: 'licenseStartDate' } },
                errors.licenseStartDate,
              );
            }}
            label="Begin datum*"
            minDate={new Date()} // rule 1: The Begin datum can not be in the past as in before today
            maxDate={licenseEndDate ? new Date(licenseEndDate) : null} // rule 3: The Begin datum can never be after the einddatum
            message={errors.licenseStartDate?.message}
            isValid={isValid['licenseStartDate']}
            errors={errors}
            setOpenStartDate={setOpenStartDate}
            disableClearButton={true}
          />
          <DatePicker
            isOpen={openEndDate}
            name="licenseEndDate"
            value={licenseEndDate}
            disabled={isDatePickerDisabled}
            register={register}
            toggleOpen={() => toggleDatePicker('picker2')}
            onDateChange={(date) => handleDateChange('picker2', date)}
            label="Einddatum"
            minDate={licenseStartDate} // rule 2: The Einddatum can never be before the Begin datum
            setOpenEndDate={setOpenEndDate}
            expirationDateIsPassedBundleDate={expirationDateIsPassedBundleDate}
            organizationBundleExpirationDate={organizationBundleExpirationDate}
          />
          <div className="flex justify-end gap-4">
            <Button
              label="Annuleren"
              type="button"
              buttonType="secondary"
              onClick={onCancelClick}
            />
            <Button
              label="Uitnodigen"
              type="submit"
              buttonType="primary"
              disabled={expirationDateIsPassedBundleDate}
            />
          </div>
        </div>
      </div>
      <ToastContainer theme="light" />
    </form>
  );
}
